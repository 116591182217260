import React from 'react'

const IndexPage = () => {
  return (
    <>
      <h1>Homepage</h1>
    </>
  )
}

export default IndexPage
